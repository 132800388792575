// https://spicyyoghurt.com/tools/easing-functions

function easeInOutQuad(t, b, c, d) {
  if ((t /= d / 2) < 1) return (c / 2) * t * t + b
  return (-c / 2) * (--t * (t - 2) - 1) + b
}

function easeOutQuad(t, b, c, d) {
  return -c * (t /= d) * (t - 2) + b
}

function easeOutExpo(t, b, c, d) {
  return t == d ? b + c : c * (-Math.pow(2, (-10 * t) / d) + 1) + b
}

function easeOutCubic(t, b, c, d) {
  return c * ((t = t / d - 1) * t * t + 1) + b
}

function easeOutQuart(t, b, c, d) {
  return -c * ((t = t / d - 1) * t * t * t - 1) + b
}

export function getPosition(element) {
  const scrollTop =
    window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop

  return element.getBoundingClientRect().top + scrollTop
}

export default function scrollTo(to, duration = 300, element) {
  const wrapper = element || document.body
  wrapper.style.pointerEvents = 'none'
  wrapper.style.willChange = 'scroll-position'

  const start = element
    ? element.scrollTop
    : window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop

  const change = to - start
  const increment = 20
  let currentTime = 0

  return new Promise((resolve) => {
    const animateScroll = () => {
      currentTime += increment

      const val = easeInOutQuad(currentTime, start, change, duration)
      if (element) {
        element.scrollTop = val
      } else {
        window.scrollTo(0, val)
      }

      if (currentTime < duration) {
        setTimeout(animateScroll, increment)
      } else {
        wrapper.style.pointerEvents = 'auto'
        wrapper.style.willChange = 'auto'
        resolve()
      }
    }

    animateScroll()
  })
}

const EASINGS = {
  easeInOutQuad: easeInOutQuad,
  easeOutQuad: easeOutQuad,
  easeOutExpo: easeOutExpo,
  easeOutCubic: easeOutCubic,
  easeOutQuart: easeOutQuart,
}

export function scrollLeft({ to, duration = 300, element, easing = 'easeOutQuart' }) {
  element.style.pointerEvents = 'none'
  element.style.willChange = 'scroll-position'

  const start = element.scrollLeft

  const change = to - start
  const increment = 20
  let currentTime = 0
  const ease = EASINGS[easing] || EASINGS.easeOutQuart

  return new Promise((resolve) => {
    const animateScroll = () => {
      currentTime += increment

      const val = ease(currentTime, start, change, duration)
      element.scrollLeft = val

      if (currentTime < duration) {
        setTimeout(animateScroll, increment)
      } else {
        element.style.pointerEvents = 'auto'
        element.style.willChange = 'auto'
        resolve()
      }
    }

    animateScroll()
  })
}
