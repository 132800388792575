import { DependencyList, useEffect } from 'react'
import { throttle } from 'lodash'

export default function useResize(func: () => void, listeners: DependencyList = []) {
  useEffect(() => {
    func()
    window.addEventListener('resize', throttle(func, 300))

    return () => {
      window.removeEventListener('resize', throttle(func, 300))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, listeners)
}
