import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'

import Dropdown from '../Dropdown'

export default function SearchFilters({
  subjects,
}: {
  subjects: Array<{
    _id: string
    title?: string
    slug?: string
    grade: { grunnskole: boolean; vgs: boolean }
  }>
  topics: Array<{
    _id: string
    title?: string
    slug?: string
  }>
  facets?: {
    subjects?: Record<string, number>
    topic?: Record<string, number>
    topicSlug?: Record<string, number>
    type?: Record<string, number>
    'usedInGrades.grunnskole'?: Record<string, number>
    'usedInGrades.vgs'?: Record<string, number>
  }
  showDurationFilter: boolean
}) {
  const router = useRouter()
  const types = [
    { id: 'lesson', title: 'Undervisningsopplegg' },
    { id: 'article', title: 'Artikler' },
    { id: 'resource', title: 'Plakater/arbeidsark' },
    { id: 'topic', title: 'Tema' },
    { id: 'definition', title: 'Ordbok' },
    { id: 'video', title: 'Video' },
    // { id: 'student', title: 'Elevopplegg' },
    { id: 'talkingType', title: 'Samtalekort' },
    { id: 'quiz', title: 'Quiz' },
  ]

  const checkedSubjects = useMemo(() => {
    return router.query.subjects?.toString() ? router.query.subjects?.toString().split(';') : []
  }, [router.query.subjects])

  const checkedGrades = useMemo(() => {
    return router.query.grades?.toString() ? router.query.grades?.toString().split(';') : []
  }, [router.query.grades])
  const checkedTypes = useMemo(() => {
    return router.query.types?.toString() ? router.query.types?.toString().split(';') : []
  }, [router.query.types])

  const updateSubject = useCallback(
    (key?: string) => () => {
      let newSubjects: unknown[] = []
      const query = router.query

      if (key !== undefined) {
        if (!checkedSubjects.includes(key)) {
          newSubjects = [...checkedSubjects, key]
        } else {
          newSubjects = checkedSubjects.filter((s) => s !== key)
        }
      }
      if (newSubjects.length) {
        query.subjects = newSubjects.join(';')
      } else {
        delete query.subjects
      }
      router.replace(
        {
          pathname: router.pathname,
          query,
        },
        undefined,
        { shallow: true },
      )
    },
    [checkedSubjects, router],
  )

  const updateGrade = useCallback(
    (key?: string) => () => {
      let newGrades: unknown[] = []
      const query = router.query

      if (key !== undefined) {
        if (!checkedGrades.includes(key)) {
          newGrades = [...checkedGrades, key]
        } else {
          newGrades = checkedGrades.filter((s) => s !== key)
        }
      }
      if (newGrades.length) {
        query.grades = newGrades.join(';')
      } else {
        delete query.grades
      }
      router.replace(
        {
          pathname: router.pathname,
          query,
        },
        undefined,
        { shallow: true },
      )
    },
    [checkedGrades, router],
  )

  const updateType = useCallback(
    (key?: string) => () => {
      let newTypes: unknown[] = []
      const query = router.query

      if (key !== undefined) {
        if (!checkedTypes.includes(key)) {
          newTypes = [...checkedTypes, key]
        } else {
          newTypes = checkedTypes.filter((s) => s !== key)
        }
      }
      if (newTypes.length) {
        query.types = newTypes.join(';')
      } else {
        delete query.types
      }
      router.replace(
        {
          pathname: router.pathname,
          query,
        },
        undefined,
        { shallow: true },
      )
    },
    [checkedTypes, router],
  )

  const hasInitialSubjects = subjects.length > 0

  const initialGrades: { id: string; label: string; selected: boolean; onClick: () => void }[] = [
    {
      id: 'grunnskole',
      label: ` Grunnskole`,
      selected: checkedGrades.includes('grunnskole'),
      onClick: updateGrade('grunnskole'),
    },
    {
      id: 'vgs',
      label: ` Vgs`,
      selected: checkedGrades.includes('vgs'),
      onClick: updateGrade('vgs'),
    },
  ]

  const initialTypes = types.map((type) => {
    const selected = checkedTypes.includes(type.id)
    return {
      id: type.id,
      label: type.title,
      selected,
      onClick: updateType(type.id),
    }
  })
  const initialSubjectList = hasInitialSubjects
    ? subjects!
        .map((subject) => {
          const selected = checkedSubjects.includes(subject.slug ?? '')

          return {
            id: subject.slug || '',
            label: subject.title || 'Unknown',
            selected,
            disabled: '',
            onClick: updateSubject(subject.slug),
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label))
    : []

  const subjectValue = () => {
    if (checkedSubjects.length > 1) {
      return `${checkedSubjects.length} fag`
    } else if (checkedSubjects.length === 1) {
      return '1 fag'
    }

    return undefined
  }

  const gradeValue = () => {
    if (checkedGrades.length > 1) {
      return `${checkedGrades.length} nivå`
    } else if (checkedGrades.length === 1) {
      return '1 nivå'
    }
    return undefined
  }

  const typeValue = () => {
    if (checkedTypes.length > 1) {
      return `${checkedTypes.length} typer`
    } else if (checkedTypes.length === 1) {
      const type = initialTypes.find((type) => {
        return type?.id === checkedTypes[0]
      })
      return type?.label || '1 type'
    }
  }

  return (
    <>
      {/* {initialTopics && hasInitialTopics && (
        <Dropdown
          label="Tema"
          value={topicValue()}
          options={initialTopics}
          spaceless
          small
          subtle
        />
      )} */}
      {hasInitialSubjects && initialSubjectList && (
        <Dropdown
          label="Fag"
          value={subjectValue()}
          options={initialSubjectList!}
          spaceless
          small
          subtle
        />
      )}

      {initialGrades && (
        <Dropdown
          label="Nivå"
          value={gradeValue()}
          options={initialGrades}
          spaceless
          small
          subtle
        />
      )}

      {initialTypes && (
        <Dropdown label="Type" value={typeValue()} options={initialTypes} spaceless small subtle />
      )}
    </>
  )
}
