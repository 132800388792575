import React from 'react'
import T from 'prop-types'
import Head from 'next/head'

export default function SEO({
  title = 'Tenk.faktisk.no',
  name = ' | Tenk',
  image,
  imageWidth = 1200,
  imageHeight = 630,
  type = 'website',
  path = '',
  description = 'Undervisningsopplegg for ungdom i kritisk mediebruk og kildebevissthet',
}) {
  const baseUrl = process.env.BASE_URL || 'https://tenk.faktisk.no'
  const URL = `${baseUrl}/${path}`
  const IMAGE_URL = image || `${baseUrl}/illustrations/tenk-delebilde.png`
  const TITLE_STRING = `${title}${name}`

  return (
    <Head>
      <title>{TITLE_STRING}</title>
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={TITLE_STRING} />

      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />

      <meta property="og:type" content={type} />
      <meta property="og:url" content={URL} />

      {IMAGE_URL && <meta name="image" content={IMAGE_URL} />}
      {IMAGE_URL && <meta property="og:image" content={IMAGE_URL} />}
      {IMAGE_URL && <meta property="twitter:image" content={IMAGE_URL} />}
      {IMAGE_URL && imageWidth && <meta property="og:image:width" content={imageWidth} />}
      {IMAGE_URL && imageHeight && <meta property="og:image:height" content={imageHeight} />}
      <meta name="twitter:card" content={image ? 'summary_large_image' : 'summary'} />
      {/* <meta name="twitter:site" content="@tenk.faktisk.no" /> */}
    </Head>
  )
}

SEO.propTypes = {
  title: T.string,
  image: T.string,
  imageWidth: T.number,
  imageHeight: T.number,
  type: T.string,
  baseUrl: T.string,
  path: T.string,
  description: T.string,
  name: T.string,
  redirectToLogin: T.bool,
}
