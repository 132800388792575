import React, { useCallback, useEffect, useRef, useState } from 'react'

import Icon from '../Icon'
import Transition from '../Transition'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'
const bem = BEMHelper(styles)

interface Props {
  label?: string
  value?: string
  children?: React.ReactNode
  spaceless?: boolean
  small?: boolean
  subtle?: boolean
  options: Array<{
    id: string
    label: string
    onClick: () => void | null
    selected?: boolean | undefined
  }>
}

const KEY_ESC = 27

export default function Dropdown({
  label,
  children,
  options,
  value,
  spaceless,
  small,
  subtle,
}: Props) {
  const triggerRef = useRef<HTMLButtonElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const [overflow, setOverflow] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<boolean>(false)
  const [origin, setOrigin] = useState<'left' | 'right'>('left')

  useEffect(() => {
    setExpanded(false)
  }, [])

  useEffect(() => {
    if (expanded && contentRef.current) {
      contentRef.current.focus({ preventScroll: true })

      if (contentRef.current.scrollHeight > contentRef.current.offsetHeight) {
        // Should not toggle this, since the overflow might disapear after overflow is set to true
        setOverflow(true)
      }
    }
  }, [expanded])

  const triggerClick = useCallback(() => {
    if (!expanded && triggerRef.current) {
      const { right } = triggerRef.current.getBoundingClientRect()
      const windowWidth = window.innerWidth
      setOrigin(right + 300 > windowWidth ? 'right' : 'left')
    }

    setExpanded((expanded) => !expanded)
  }, [expanded])

  const toggle = () => {
    setExpanded((expanded) => !expanded)
  }

  const close = () => {
    setExpanded(false)
  }

  useEffect(() => {
    const handleKeyDown = ({ keyCode }: { keyCode: number }) => {
      if (keyCode === KEY_ESC) {
        close()
      }
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const handleOptionClick = (action: () => void) => () => {
    close()
    action()
  }

  return (
    <div {...bem('', { spaceless, overflow })}>
      <button
        {...bem('trigger', { expanded, small, subtle, selected: !!value && subtle })}
        type={'button'}
        onClick={triggerClick}
        ref={triggerRef}
      >
        {label}
        {value ? `: ${value}` : undefined}
        <Icon icon="chevron" direction="down" />
      </button>

      <Transition updateKey={expanded.toString()} {...bem('animation', { expanded })}>
        {expanded && (
          <>
            <button type="button" onClick={toggle} {...bem('close')} aria-label="Lukk">
              <Icon icon="close" />
            </button>
            <h3 {...bem('title')}>{label}</h3>
            <div {...bem('wrapper', origin)}>
              {options ? (
                <nav {...bem('content', 'options')} ref={contentRef}>
                  {options.map(({ id, label, onClick, selected }) => (
                    <button
                      type="button"
                      key={id}
                      onClick={handleOptionClick(onClick)}
                      {...bem('option', { selected, subtle })}
                    >
                      {label}
                    </button>
                  ))}
                </nav>
              ) : (
                <div {...bem('content')} ref={contentRef}>
                  {children}
                </div>
              )}
            </div>
            <button
              type="button"
              aria-label="Lukk"
              {...bem('backdrop')}
              onFocus={close}
              onClick={close}
            />
          </>
        )}
      </Transition>
    </div>
  )
}
