import { isNil } from 'lodash'

import { formatDate } from './date'

export function commaList(items) {
  if (!items || items.length === 0) {
    return
  }

  return [items.slice(0, items.length - 1).join(', '), items[items.length - 1]]
    .filter((item) => Boolean(item))
    .join(' og ')
}

export function getGrades(subjects) {
  const grades = []
  const { grunnskole, vgs } = subjects.reduce(
    (result, item) => {
      if (item?.grade?.grunnskole) {
        result.grunnskole = true
      }

      if (item?.grade?.vgs) {
        result.vgs = true
      }

      return result
    },
    { grunnskole: false, vgs: false },
  )

  if (grunnskole) {
    grades.push({ label: '8. - 10. klasse', value: 'grunnskole' })
  }

  if (vgs) {
    grades.push({ label: '1. - 3. VGS', value: 'vgs' })
  }

  return grades.length ? grades : null
}

export function blockToPlainText(blocks = []) {
  return (
    blocks
      // loop through each block
      .map((block) => {
        // if it's not a text block with children,
        // return nothing
        if (block._type !== 'block' || !block.children) {
          return ''
        }

        // loop through the children spans, and join the
        // text strings
        return block.children.map((child) => child.text).join('')
      })
      // join the paragraphs leaving split by two linebreaks
      .join('\n\n')
  )
}

export function limit(number) {
  return (_, index) => !number || index < number
}

export function byline({ publishedAt, _updatedAt, author, loanArticle, loanArticleSource }) {
  if (!publishedAt && !_updatedAt && !author) {
    return null
  }

  const date = _updatedAt || publishedAt || ''
  const who =
    (loanArticle
      ? loanArticleSource && `${loanArticleSource}, `
      : author?.title && `${author?.title}, `) || ''

  return `${who}${formatDate(date)}`
}

export function hasLongWord(string, length = 15) {
  return (string || '').split(' ').some((word) => word.length >= length)
}

// Map number x from range [a, b] to [c, d]
export function map(x, a, b, c, d) {
  return ((x - a) * (d - c)) / (b - a) + c
}

// Linear interpolation
export function lerp(a, b, n) {
  return (1 - n) * a + n * b
}

// Clamp val within min and max
export function clamp(val, min, max) {
  return Math.max(Math.min(val, max), min)
}

export function formatThousands(n) {
  if (isNil(n)) {
    return ''
  }

  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export function randomFromArray(arr) {
  return arr[Math.floor(Math.random() * arr.length)]
}
