/* eslint-disable @typescript-eslint/no-explicit-any */
import Link from 'next/link'
import { CSSProperties, useRef } from 'react'

import useIntersection from '@/hooks/useIntersection'
import getTopic from '@/lib/topic'
import styles from './Styles.module.scss'

import BEMHelper from '@/helpers/bem'
const bem = BEMHelper(styles)

interface Props {
  items?: {
    _id: string
    slug: string
    title: string
    topicTheme: any
  }[]
  title?: string
  frontpage?: boolean
}

export default function TopicEntry({ items = [], title, frontpage }: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const isVisible = useIntersection(wrapperRef, {}, true)

  if (!items?.length) {
    return null
  }

  const SubTitle = title ? 'h3' : 'h2'

  return (
    <nav {...bem('', { enter: isVisible, frontpage })} ref={wrapperRef}>
      {title && <h2 {...bem('main-title')}>{title}</h2>}

      <div {...bem('content')}>
        {items.map(({ _id, title, slug, topicTheme }) => {
          const topic = getTopic(topicTheme)

          if (!topic?.illustration?.path) {
            console.warn(topic?.illustration)
          }

          return (
            <Link
              href={`/tema/${slug}`}
              {...bem('item')}
              key={_id}
              style={
                {
                  '--color-background': topic.background,
                  '--color-promoted': topic.promoted,
                } as CSSProperties
              }
            >
              {topic?.illustration?.path && (
                <div key={_id + 2} {...bem('illustration')}>
                  <picture>
                    {topic.illustration.webp && (
                      <source srcSet={topic.illustration.webp} type="image/webp" />
                    )}
                    <source srcSet={topic.illustration.path} type="image/png" />
                    <img
                      {...bem('image')}
                      src={topic.illustration.path}
                      alt=""
                      width={800}
                      height={800 / topic.illustration.aspect}
                    />
                  </picture>
                </div>
              )}

              <SubTitle key={_id + 3} {...bem('title')}>
                {title}
              </SubTitle>
            </Link>
          )
        })}
      </div>
    </nav>
  )
}
