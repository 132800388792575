/* eslint-disable indent */
import React, { useState } from 'react'
import T from 'prop-types'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'
import getTopic from '../../lib/topic'
import { Entry } from '../LessonEntry'
import Button from '../Button'

const bem = new BEMHelper(styles)

export default function PromotedLessons({ title, preamble, lessons }) {
  const [expanded, setExpanded] = useState(false)

  if (!lessons?.length) {
    return null
  }

  const showExpand = !expanded && lessons.length > 2

  const expand = () => {
    setExpanded(true)
  }

  const getType = () => {
    if (lessons.length > 3) {
      return ['three', 'more']
    } else if (lessons.length === 3) {
      return 'three'
    } else if (lessons.length === 2) {
      return 'double'
    } else if (lessons.length === 1) {
      return 'single'
    }

    return null
  }

  const type = getType()
  const topic = getTopic(lessons[0]?.topicTheme)

  const styles =
    type === 'single' && topic
      ? {
          '--color-dark': topic.dark,
          '--color-promoted': topic.promoted,
          '--color-background': topic.background,
          '--color-light': topic.light,
        }
      : null

  return (
    <section {...bem('', type)} style={styles}>
      <div {...bem('content')}>
        <h3 {...bem('title')}>{title}</h3>
        <p {...bem('preamble')}>{preamble}</p>
      </div>

      <ul {...bem('list', { expanded })}>
        {lessons.map((lesson) => (
          <Entry
            key={lesson._id}
            {...lesson}
            entryType="lesson"
            titleNode="h4"
            {...bem('entry', { collapsed: !expanded })}
          />
        ))}
      </ul>

      {showExpand && (
        <div {...bem('more', { 'hide-lg': lessons.length <= 3 })}>
          <Button small label="Vis flere..." onClick={expand} />
        </div>
      )}
    </section>
  )
}

PromotedLessons.propTypes = {
  title: T.string.isRequired,
  preamble: T.string.isRequired,
  lessons: T.array.isRequired,
}
