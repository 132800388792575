/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState } from 'react'

import BEMHelper from '../../helpers/bem'
import portableToPlain from '../../helpers/portableToPlain'
import Button from '../Button'
import styles from './Styles.module.scss'

const bem = BEMHelper(styles)

interface DefinitionSearchListProps {
  items?: any[]
  ungrouped?: boolean
}

export default function DefinitionSearchList({ items = [] }: DefinitionSearchListProps) {
  const [showMore, setShowMore] = useState(false)
  const entriesRef = useRef<HTMLDivElement>(null)

  if (!items?.length) {
    return null
  }

  const displayedItems = showMore ? items : items.slice(0, 3)
  const remainingItems = items.length - displayedItems.length

  const renderItems = (items: any[]) =>
    items.map(({ _id, title, text, body }: any) => (
      <div key={_id} {...bem('item')}>
        <dt key={`${_id}-dt`} {...bem('type')}>
          {title}
        </dt>
        <dd key={`${_id}-dd`} {...bem('description')}>
          {portableToPlain(text) || body}
        </dd>
      </div>
    ))

  return (
    <div {...bem('')} ref={entriesRef}>
      <dl {...bem('list')}>{renderItems(displayedItems)}</dl>

      {items.length > 3 && !showMore && (
        <Button onClick={() => setShowMore(true)} more>
          {remainingItems} flere
        </Button>
      )}
    </div>
  )
}
