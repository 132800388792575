import React, { useRef, useEffect, useCallback } from 'react'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'
import { map, lerp } from '../../helpers/utils'

const AMT_SCROLL = 0.17
const AMT_OFFSET = 0.22

const bem = new BEMHelper(styles)

export default function Illustration() {
  const ref = useRef()
  const init = useRef()
  const observer = useRef()
  const visible = useRef(true)
  const entered = useRef(false)
  const scroll = useRef(0)
  const winsize = useRef({ width: 0, height: 0 })
  const offsets = useRef([])
  const images = useRef([])

  init.current = () => {
    if (visible.current) {
      update()
    }

    requestAnimationFrame(init.current)
  }

  const computeOffsets = useCallback(() => {
    return images.current.map((element) => {
      const val = parseInt(element.getAttribute('data-parallax'), 10)
      return map(window.self.current - window.scrollY, winsize.current.height, 0, val, -val / 2)
    })
  }, [])

  const updateImages = useCallback(() => {
    images.current.forEach((element, index) => {
      element.setAttribute('style', `transform: translateY(${offsets.current[index]}%)`)
    })
  }, [])

  const handleResize = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect()
      window.self.current = rect.top + window.pageYOffset
      scroll.current = window.pageYOffset
      winsize.current = { width: window.innerWidth, height: window.innerHeight }
    }
  }

  const update = useCallback(() => {
    const currentOffsets = computeOffsets()

    offsets.current = offsets.current.map((value, index) =>
      !entered.current ? currentOffsets[index] : lerp(value, currentOffsets[index], AMT_OFFSET),
    )

    updateImages()

    const currentScroll = window.pageYOffset
    scroll.current = !entered.current
      ? currentScroll
      : lerp(scroll.current, currentScroll, AMT_SCROLL)

    if (!entered.current) {
      entered.current = true
    }
  }, [computeOffsets, updateImages])

  useEffect(() => {
    const currentRef = ref.current
    if (ref.current) {
      images.current = [...ref.current.querySelectorAll('[data-parallax]')]
      offsets.current = computeOffsets()

      handleResize()
      updateImages()

      window.addEventListener('resize', handleResize)

      observer.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          visible.current = entry.isIntersecting

          if (!visible.current) {
            entered.current = false
            update()
          }
        })
      })

      observer.current.observe(ref.current)

      requestAnimationFrame(init.current)

      return () => {
        window.removeEventListener('resize', handleResize)

        if (currentRef) {
          observer.current.unobserve(currentRef)
        }
      }
    }
  }, [update, computeOffsets, updateImages])

  return (
    <svg viewBox="0 -175 1272 1600" xmlns="http://www.w3.org/2000/svg" {...bem('')} ref={ref}>
      <image
        href="/illustrations/main-illustration/DM.png"
        width="325"
        height="447"
        x="278"
        // y="11"
        y="-100"
        data-parallax="-30"
      />
      <image
        href="/illustrations/main-illustration/web.png"
        width="693"
        height="328"
        x="320"
        // y="210"
        y="180"
        data-parallax="-15"
      />
      <image
        href="/illustrations/main-illustration/snap.png"
        width="329"
        height="572"
        x="855"
        // y="415"
        y="500"
        data-parallax="12"
      />
      <image
        href="/illustrations/main-illustration/artikkel.png"
        width="527"
        height="440"
        x="375"
        // y="447"
        y="407"
        data-parallax="-5"
      />
      <image
        href="/illustrations/main-illustration/video.png"
        width="754"
        height="380"
        x="505"
        // y="22"
        y="-10"
        data-parallax="-7"
      />
      <image
        href="/illustrations/main-illustration/person.png"
        width="330"
        height="1093"
        x="451"
        y="289"
      />
      <image
        href="/illustrations/main-illustration/boble.png"
        width="392"
        height="313"
        x="108"
        // y="750"
        y="980"
        data-parallax="25"
      />
      <image
        href="/illustrations/main-illustration/insta.png"
        width="416"
        height="581"
        x="14"
        // y="258"
        y="350"
        data-parallax="14"
      />
    </svg>
  )
}
