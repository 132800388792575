export const URLS = {
  topic: 'tema',
  lesson: 'undervisningsopplegg',
  student: 'elevopplegg',
  article: 'artikkel',
  presentation: 'presentasjon',
  quiz: 'quiz',
  talkingType: 'samtalekort',
  subTopic: 'samling',
}

export const LINK_TYPES = {
  topic: 'Tema',
  lesson: 'Opplegg',
  student: 'Elevside',
  article: 'Artikkel',
  presentation: 'Presentasjon',
  quiz: 'Quiz',
  talkingType: 'Samtalekort',
  subTopic: 'Samling',
}

export const FILE_TYPES = {
  pdf: 'PDF',
  mov: 'Video',
  docx: 'Word',
  doc: 'Word',
  pptx: 'Powerpoint',
  ppt: 'Powerpoint',
  key: 'Keynote',
}

export default function getUrl(type, slug) {
  const url = URLS[type]

  if (url) {
    return `/${url}/${slug}`
  }

  return '/'
}
