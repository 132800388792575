import { format } from 'date-fns'
import nb from 'date-fns/locale/nb'

export function formatDate(date, dateFormat = 'd. MMM yyyy', fallback = '—') {
  if (!date) {
    return fallback
  }

  return format(new Date(date), dateFormat, { locale: nb })
}
