import debounce from 'lodash/debounce'
import { useRouter } from 'next/router'
import { ReactNode, useEffect, useState } from 'react'

import Icon from '@/components/Icon'

import BEMHelper from '@/helpers/bem'
import styles from './Styles.module.scss'
const bem = BEMHelper(styles)

interface Props {
  loading: boolean
  defaultValue: string
  children?: ReactNode
  placeholder?: string
  small?: boolean
}

export default function Search({
  loading,
  defaultValue,
  children,
  placeholder = 'Søk',
  small,
}: Props) {
  const router = useRouter()
  const [routerReady, setRouterReady] = useState(false)

  useEffect(() => {
    if (router.isReady) {
      setRouterReady(true)
    }
  }, [router.isReady])

  const updateUrl = debounce((q: string) => {
    if (q === '' || q === undefined || q === null) {
      delete router.query.q
      router.replace(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
          },
        },
        undefined,
        { shallow: true },
      )
    } else {
      router.replace(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            q: q ? q : undefined,
          },
        },
        undefined,
        { shallow: true },
      )
    }
  }, 400)

  return (
    <form
      {...bem('', { loading, small })}
      onSubmit={(event) => {
        event.preventDefault()
        const formData = new FormData(event.target as HTMLFormElement)
        const searchValue = formData.get('search')
        if (searchValue !== null) {
          updateUrl(searchValue.toString())
        }
      }}
    >
      <div {...bem('wrapper')}>
        <input
          type="search"
          name="search"
          autoFocus
          autoComplete="off"
          placeholder={routerReady ? placeholder : ''}
          spellCheck="false"
          onChange={(event) => {
            updateUrl(event.target.value)
          }}
          defaultValue={defaultValue}
          disabled={!routerReady}
          {...bem('input')}
        />

        <button type="submit" aria-label="Søk" {...bem('submit')}>
          <Icon icon="search" />
        </button>
      </div>
      {children && <div {...bem('filters', { small })}>{children}</div>}
    </form>
  )
}
