import React from 'react'
import T from 'prop-types'
import Image from 'next/image'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'
import Button from '../Button'
import { getImageUrl } from '../../api/sanity'
import getUrl from '../../helpers/links'

const bem = new BEMHelper(styles)

export default function Hero({ title, preamble, link, label, image }) {
  const imageURL = getImageUrl(image).width(1400).url()
  const href = link?.reference
    ? getUrl(link?.reference?._type, link?.reference?.slug)
    : link?.external

  if (!href) {
    return null
  }

  return (
    <div href={href} {...bem('')}>
      <Image
        {...bem('image')}
        src={imageURL}
        alt={image?.alt || ''}
        height={image.height || 0}
        width={image.width || 0}
      />

      <div {...bem('content')}>
        <h3 {...bem('title')}>{title}</h3>
        <p {...bem('preamble')}>{preamble}</p>

        <Button href={href} label={label} primary {...bem('cta')} />
      </div>
    </div>
  )
}

Hero.propTypes = {
  title: T.string.isRequired,
  preamble: T.string.isRequired,
  image: T.object.isRequired,
  link: T.object.isRequired,
  label: T.string.isRequired,
}
