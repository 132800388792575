import { groq } from 'next-sanity'
import ErrorPage from 'next/error'
import { useRouter } from 'next/router'
import T from 'prop-types'
import React from 'react'

import CollectionEntry from '@/components/CollectionEntry'
import Hero from '@/components/Hero'
import NavList from '@/components/NavList'
import PromotedLessons from '@/components/PromotedLessons'
import SEO from '@/components/SEO'
import SanityPreview from '@/components/SanityPreview'
import SearchSection from '@/components/SearchSection'
import Section from '@/components/Section'
import TopicEntry from '@/components/TopicEntry'

import { frontpageQuery, topicThemeQuery } from '../api'
import { getClient } from '../api/sanity'

import useColor from '@/hooks/useColor'
import useSettings from '@/hooks/useSettings'

import { MENU } from '../constants'

const query = groq`
  {
    "topics": *[_type == "topic" && isHidden != true] {
      _id,
      'slug': slug.current,
      title,
      preamble,
      "topicTheme": ${topicThemeQuery}
    },
    "subjects": *[_type == "subject"] {
      ...,
      "slug": slug.current,
    },
    "frontpage": *[_type == "frontpage"]${frontpageQuery} | order(_updatedAt desc)[0],
  }
  `

export default function Frontpage({ preview, pageData, error, status, themes }) {
  const settings = useSettings()
  const router = useRouter()

  useColor(null, status)

  if (!router.isFallback && !pageData) {
    return <ErrorPage statusCode={404} />
  }

  if (error) {
    return <ErrorPage statusCode={error.statusCode} title={error.title} />
  }

  return (
    <SanityPreview query={query} initialData={pageData} preview={preview}>
      {({ topics, subjects, frontpage }) => {
        const hasSearch = router.asPath.startsWith('/?') && router.asPath.includes('=')
        const showSections = frontpage?.content?.length > 0 && !hasSearch

        return (
          <>
            <SEO />
            {!hasSearch && <NavList active="home" items={settings?.menu || MENU} hideBack />}
            <SearchSection themes={themes} subjects={subjects} topics={topics} />

            {showSections && (
              <Section>
                {frontpage.content.map(({ _key, _type, ...item }) => {
                  switch (_type) {
                    case 'lessonPromotion':
                      return <PromotedLessons key={_key} {...item} />

                    case 'promotedLink':
                      return <Hero key={_key} {...item} />

                    case 'subTopicPromotion':
                      return <CollectionEntry key={_key} {...item} />

                    case 'topics':
                      return <TopicEntry key={_key} {...item} items={topics} frontpage />

                    default:
                      return null
                  }
                })}
              </Section>
            )}
          </>
        )
      }}
    </SanityPreview>
  )
}

Frontpage.propTypes = {
  pageData: T.shape({
    subjects: T.array,
    frontpage: T.object,
    topics: T.arrayOf(
      T.shape({
        _id: T.string.isRequired,
        slug: T.string.isRequired,
        title: T.string.isRequired,
      }),
    ),
  }),
  status: T.string,
  preview: T.bool.isRequired,
  error: T.shape({
    statusCode: T.number.isRequired,
    title: T.string.isRequired,
    purpose: T.string,
  }),
}

export async function getStaticProps({ preview = false }) {
  const pageData = await getClient(preview).fetch(query)
  const themes = await getClient(preview).fetch(`*[_type == 'topic']${topicThemeQuery}`)
  const subjects = await getClient(preview).fetch(
    `*[_type == 'subject']{ _id, "slug": slug.current, title }`,
  )

  return {
    props: {
      preview,
      pageData,
      themes,
      subjects,
    },
    revalidate: 10, // Revalidate at most every 10th second
  }
}
